import React from 'react';
import PropTypes from 'prop-types';
import { Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

import Link from '~/shared/components/Link';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import { richTextPropType } from '~/shared/util/shared-prop-types';

// TODO: Add Icon component.
// TODO: Remove benefit HTML.

const Header = styled(Headline)`
  margin-bottom: 16px;
`;

/**
 * Highlight a benefit of using SumUp
 */
function Benefit({
  headline = '',
  content = {},
  link,
  className,
  contentType,
  name,
  id,
}) {
  return (
    headline && (
      <div className={className} data-elbcontext="component:benefit">
        <Link
          href={link}
          trackingContentEntry={{
            contentType,
            contentEntryName: name,
            contentEntryId: id,
          }}
        >
          <a>
            <Header
              as="h2"
              size="two"
              dangerouslySetInnerHTML={{
                __html: headline,
              }}
            />
          </a>
        </Link>
        <RichText
          richText={content}
          renderNode={inline.createRenderNode(inline.ONE, {
            contentType,
            contentEntryName: name,
            contentEntryId: id,
          })}
          renderMark={inline.createRenderMark()}
        />
      </div>
    )
  );
}

Benefit.propTypes = {
  /**
   * A concise headline.
   */
  headline: PropTypes.string,
  /**
   * A concise summary of the benefit.
   */
  content: richTextPropType,
  /**
   * A link to more information about the benefit.
   */
  link: PropTypes.string,
  /**
   * An icon illustrating the benefit.
   */
  icon: PropTypes.string,
  /**
   * Customize the styles.
   */
  className: PropTypes.string,
  contentType: PropTypes.string,
  /**
   * Name in contentful for tracking
   */
  name: PropTypes.string,
  /**
   * ID in contentful for tracking
   */
  id: PropTypes.string,
};

/**
 * @component
 */
export default Benefit;
