import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

export function getBenefitsWithHeadlinesFromRichtextColumn(benefits) {
  const benefitsClone = JSON.parse(JSON.stringify(benefits)) || [];

  benefitsClone.forEach((benefit) => {
    if (benefit.contentType === 'richtextColumn') {
      // eslint-disable-next-line no-param-reassign
      let headline = get('content.content.0.content.0.value', benefit);
      const headlineStruck = get(
        'content.content.0.content.1.data.target.content',
        benefit,
      );

      if (headlineStruck) {
        headline = `${headline}<s>${headlineStruck}</s>`;
      }

      // eslint-disable-next-line no-param-reassign
      benefit.headline = headline;

      const isATwoLineDocument =
        get('content.content.length', benefit) === 2 &&
        get('content.content.0.nodeType', benefit) === 'heading-3' &&
        get('content.content.1.nodeType', benefit) === 'heading-5';

      const isAThreeLineDocument =
        get('content.content.length', benefit) === 3 &&
        get('content.content.0.nodeType', benefit) === 'heading-3' &&
        get('content.content.1.nodeType', benefit) === 'heading-5' &&
        get('content.content.2.nodeType', benefit) === 'paragraph' &&
        get('content.content.2.content.0.value', benefit) === '';

      if (isATwoLineDocument || isAThreeLineDocument) {
        // change nodeType of second line from heading-5 to paragraph to have
        // the same formatting as other benefit list items
        // eslint-disable-next-line no-param-reassign
        benefit.content.content[1].nodeType = 'paragraph';
      }

      // remove headline from content (first item of array)
      const content = get('content.content', benefit);
      if (!isEmpty(content)) {
        content.shift();
      }
    }
  });

  return benefitsClone;
}
