import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';

import Benefit from './components/Benefit';
import * as BenefitListService from './BenefitListService';

import dataSelector from '~/shared/util/data-selector';

const colStyles = ({ theme }) => css`
  ${theme.mq.untilMega} {
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.tera};
    }
  }
`;

const StyledCol = styled(Col)(colStyles);

/**
 * A list of Benefits
 */
const BenefitList = ({ benefits = [], className }) => {
  if (isEmpty(benefits)) {
    return null;
  }
  // eslint-disable-next-line max-len
  const benefitsWithHeadlines =
    BenefitListService.getBenefitsWithHeadlinesFromRichtextColumn(benefits);
  const mega = benefitsWithHeadlines.length === 2 ? 6 : 4;
  return (
    <Grid
      {...{ className }}
      data-selector={dataSelector('section', 'benefit_list')}
      data-elbcontext="component:benefit_list"
    >
      <Row>
        {benefitsWithHeadlines.map((benefit, i) => (
          <StyledCol
            key={i}
            span={{ default: 12, mega }}
            data-selector={dataSelector(
              `benefit_list_${i + 1}`,
              'benefit_list',
            )}
          >
            <Benefit {...benefit} />
          </StyledCol>
        ))}
      </Row>
    </Grid>
  );
};

BenefitList.propTypes = {
  /**
   * An array of benefit objects. See benefit proptypes for reference.
   */
  benefits: PropTypes.arrayOf(PropTypes.object),
  /**
   * Customize the styles.
   */
  className: PropTypes.string,
};

/**
 * @component
 */
export default BenefitList;
